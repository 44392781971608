<template>
  <div>
    <Header></Header>
    <div class="banner">
      <img :src="`${$url}/banner/2.png`" alt="">
      <div class="all-title-ani">医疗软件开发</div>
      <div class="all-title-ani all-ani-delay">为植发医疗机构提供集IaaS、PaaS、SaaS为一体的综合云服务解决方案，具有高稳定性、统一管理、可视化运营等特点，助力医疗机构构建稳定安全的云环境和健康的云生态。</div>
    </div>
    <div class="medical">
      <div class="product-services">
        <div class="All-title">产品服务</div>
        <div>
          <div>
            <div>
              <img :src="`${$url}/medical/1.png`" alt="">
              <img :src="`${$url}/medical/11.png`" alt="">
              <div>快速在线问诊</div>
              <div>方便准确的与医生进行沟通，维持医生与患者长期稳定的沟通。实现让患者足不出户就能问医，减少医患矛盾。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/medical/2.png`" alt="">
              <img :src="`${$url}/medical/12.png`" alt="">
              <div>智能健康问诊</div>
              <div>可随时随地通过可穿戴设备实时监测体征数据，了解自身健康状况，为您的健康全面护航。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/medical/3.png`" alt="">
              <img :src="`${$url}/medical/13.png`" alt="">
              <div>健康用药管家</div>
              <div>帮助医院以及医生为患者提供完善的诊后用药服务，减轻药师工作压力，提高患者用药依从性。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/medical/4.png`" alt="">
              <img :src="`${$url}/medical/14.png`" alt="">
              <div>智能AI在线</div>
              <div>在线问诊植入AI能力，助力提升医患问答效率，减少医生录入工作，辅助医生高质量解答患者疑问。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-ecology">
        <div class="All-title">产品生态</div>
        <img :src="`${$url}/medical/5.png`" alt="">
      </div>
      <div class="application-scenario">
        <div class="All-title">应用场景</div>
        <div class="application-box">
          <div>
            <img :src="`${$url}/medical/6.png`" alt="">
            <div>
              <div>为各级医院提供一系列解决方案，通过互联网信息技术连接医院、医生和患者，引导医疗资源下沉，优化医疗资源配置、提升医疗服务效率，打造中国最具技术领先优势的互联网诊疗平台。提升各区域的卫生信息化水平，提高卫生管理能力及管理效率。</div>
              <div>
                <div>医疗资源下沉</div>
                <div>优化医疗资源</div>
                <div>提升医疗效率</div>
                <div>打破传统医疗</div>
              </div>
            </div>
            <div></div>
            <div>互联网医院</div>
          </div>
          <div>
            <img :src="`${$url}/medical/7.png`" alt="">
            <div>
              <div>为各级医院提供一系列解决方案，帮助医院解决系统维护困难，医院间信息孤岛、体验不好等问题。实现医院互联，同时保障信息传输和数据安全，提供人工智能辅助，优化患者就医流程，降低医院的业务负载。</div>
              <div>
                <div>解决维护困难</div>
                <div>加强医患体验</div>
                <div>提高患者周转</div>
                <div>完善就医体验</div>
              </div>
            </div>
            <div></div>
            <div>医院HIS</div>
          </div>
          <div>
            <img :src="`${$url}/medical/15.png`" alt="">
            <div>
              <div>拥有覆盖全国的医疗资源和合作伙伴，可为各中西医诊所提供综合解决方案，针对诊所的特点，实现多科室覆盖，预约挂号，远程问诊，中医开方，中医开药，全科辅助诊断，影像智能分析，电子病历等功能和场景，为诊所助力，提升诊所能力。</div>
              <div>
                <div>提升医生能力</div>
                <div>家医团队就医</div>
                <div>实现多科覆盖</div>
                <div>提升诊所能力</div>
              </div>
            </div>
            <div></div>
            <div>社区门诊</div>
          </div>
          <div>
            <img :src="`${$url}/medical/16.png`" alt="">
            <div>
              <div>关注家庭用户健康，为家庭客户提供综合健康解决方案，包涵智能可穿戴硬件，智能人工智能服务等。依托强大的医疗资源和多年的服务经验，为家庭客户提供在线问诊、预约挂号、医学百科、视频问诊等功能，为家庭健康保驾护航。</div>
              <div>
                <div>主动健康管理</div>
                <div>居家医疗智化</div>
                <div>健康生活方式</div>
                <div>满足康养需求</div>
              </div>
            </div>
            <div></div>
            <div>居家管理</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
.medical {
  .product-services {
    width: 100%;
    margin-top: -1px;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/3.png')
      no-repeat;
    background-size: cover;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 100px;

    > div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 64px;

      > div {
        width: 340px;
        height: 424px;
        background: #ffffff;
        box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin-right: 22px;
        margin-bottom: 22px;
        text-align: center;

        position: relative;
        &::after {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          transition: all 0.5s ease-in-out;
          opacity: 0;
        }
        &:hover {
          &::after {
            opacity: 1;
            background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/medical/10.png')
              no-repeat;
            background-size: 100% 100%;
          }
          > div {
            img:nth-child(1) {
              display: none;
            }
            img:nth-child(2) {
              display: inline-block;
            }
            > div:nth-child(3) {
              color: #ffffff;
            }

            > div:nth-child(4) {
              color: #ffffff;
            }
          }
        }
        > div {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          img:nth-child(1) {
            width: 64px;
            height: 64px;
            margin-top: 40px;
            margin-bottom: 24px;
          }
          img:nth-child(2) {
            width: 64px;
            height: 64px;
            margin-top: 40px;
            margin-bottom: 24px;
            display: none;
          }

          > div:nth-child(3) {
            font-size: 30px;
            font-weight: 500;
            color: #181818;
            margin-bottom: 20px;
          }

          > div:nth-child(4) {
            width: 292px;
            font-size: 26px;
            font-weight: 400;
            color: #7b7b7b;
            line-height: 44px;
            margin: 0 auto;
          }
        }
      }

      & div:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  .product-ecology {
    width: 100%;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/8.png')
      no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 100px;
    text-align: center;

    img {
      margin-top: 100px;
      width: 618px;
    }
  }
  .application-scenario {
    background: #f5f6f8;
    padding-top: 100px;
    padding-bottom: 48px;

    .application-box {
      width: 702px;
      margin: 64px auto 0;
      > div {
        position: relative;
        margin-bottom: 32px;
        img {
          width: 100%;
        }
        > div:nth-child(2) {
          width: 100%;
          background: #ffffff;
          border-radius: 0px 0px 8px 8px;
          opacity: 1;
          padding: 28px 24px 7px 24px;

          > div:nth-child(1) {
            width: 654px;
            font-size: 26px;
            font-weight: 400;
            color: #181818;
            line-height: 40px;
          }
          > div:nth-child(2) {
            display: flex;
            flex-wrap: wrap;
            margin-top: 24px;
            > div {
              width: 50%;
              font-size: 26px;
              font-weight: 400;
              color: #181818;
              margin-bottom: 28px;
              position: relative;
              padding-left: 40px;
              &::after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 32px;
                height: 32px;
                background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/medical/9.png')
                  no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
        > div:nth-child(3) {
          position: absolute;
          top: 274px;
          left: 0;
          width: 702px;
          height: 86px;
          line-height: 86px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            #000000 100%
          );
          opacity: 0.64;
        }
        > div:nth-child(4) {
          position: absolute;
          top: 306px;
          left: 24px;
          font-size: 30px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
